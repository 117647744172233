html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}
.logoImg1 {
  aspect-ratio: 1/1;
  border-radius: 100%;
  height: 50px;
  background-color: gray;
  object-fit: cover;
}

.logoImg2 {
  aspect-ratio: 1/1;
  border-radius: 100%;
  height: 150px;
  border: 2px solid;
  object-fit: cover;
}
.logoImg3 {
  border-radius: 100%;
  height: 200px;
  /* object-fit: cover; */
  object-fit: contain;
}

.logoImg4 {
  height: clamp(170px, 15vw, 250px);
}

.logoImg5 {
  /* height: clamp(100px, 10vw, 150px); */
  height: 150px;
}

.logoImg6 {
  height: 250px;
}

.NewsImg {
  aspect-ratio: 1/1;
}

/* h1,
h2,
h3,
h4,
h5,
h6, */
p {
  color: black;
}

p {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .cardItem {
    margin: 20px !important;
  }
  .xl-w90 {
    width: 90% !important;
  }
}

@media (max-width: 1400px) {
  .newsC {
    width: 65% !important;
  }
  .xl_col {
    flex-direction: column;
  }
  .xl_m-0 {
    margin: 0px 0px 15px 0px !important;
  }
  .sticky_res {
    top: 135px !important;
  }
  .xxl-invisible {
    display: none !important;
  }
  .xxl-center {
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .ViewPDF {
    width: "912px" !important ;
    height: "72px";
  }
  .vi {
    visibility: hidden !important;
  }
  .BS {
    width: 100% !important;
  }
  .xl_pt-3 {
    padding-top: 25px;
  }
  .newsC {
    width: 70% !important;
  }
  .bp_gover {
    width: 80% !important;
  }
  .navbar_padding {
    padding: 0px !important;
  }
  .sticky_res {
    visibility: hidden;
    display: none !important;
  }
  .xl_mxauto {
    margin-inline: auto !important;
    margin-top: 20px;
  }
}

@media (max-width: 992px) {
  .lg_mt20 {
    margin-top: 20px !important;
  }
  .lg_mt60 {
    margin-top: 60px !important;
  }
  .lg_p {
    padding: 25px;
  }
  #BusinessBg2 {
    margin-top: 20px;
  }
  .newsC {
    width: 80% !important;
  }
  .xl_start {
    text-align: start !important;
    width: 100% !important;
  }
  .lg_pt0 {
    padding-top: 0px !important;
  }
  .lg_pt5 {
    padding-top: 5px !important;
  }
  .lg_w100 {
    width: 100% !important;
  }
  .lg_p0 {
    padding: 0px;
  }
  .bp_gover {
    width: 100% !important;
  }
  .narbar_brand {
    height: 25px !important;
  }
  .narbar_link {
    height: 20px !important;
  }
  .narbar_font {
    font-size: 10px !important;
  }
  .navbar_padding {
    padding: 0px !important;
  }
  .sidebar_width {
    width: 40% !important;
  }
  .xl-col {
    flex-direction: column;
    gap: 5px !important;
  }
  .xl-visible {
    visibility: visible !important;
    display: flex !important;
    /* display: block !important; */
  }
  .xl-invisible {
    visibility: hidden !important;
    display: none !important;
  }
  .xl-w100 {
    width: 100% !important;
  }
  .xl-py35 {
    padding-block: 35px;
  }
  .modalWidth {
    width: 500px !important;
  }
}

@media (max-width: 768px) {
  .news-col {
    flex-direction: column !important;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
  }
  .cardItem {
    margin: 15px 0px !important;
  }
  .text_size {
    font-size: 0.95rem !important;
  }
  .logoImg2 {
    height: 100px;
  }
  .mx_auto {
    margin-inline: auto;
  }
  .modalWidth {
    width: 350px !important;
    padding: 30px !important;
  }
  .pdfFont {
    margin-left: 0px !important;
  }
  .pdfRes {
    flex-direction: column;
    align-items: start !important;
    gap: 5px;
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .homeModal {
    width: 300px !important;
  }
  .lgheight {
    height: 350px !important;
  }
  .newsC {
    width: 90% !important;
  }
  .mobile_w95 {
    width: 95% !important;
  }
  .mobile_w90 {
    width: 90% !important;
  }
  .mobile_w370 {
    width: 300px !important;
    overflow-x: scroll;
  }
  .mobile_w250 {
    height: 250px !important;
  }
  .logoImg3 {
    height: 150px !important;
  }
  .mobile_col {
    flex-direction: column;
    align-items: center;
    margin: 5px 0px !important;
  }
  .mobile_column {
    flex-direction: column;
  }
  .mobile_flex {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  .mobile_center {
    justify-content: center;
  }
  .mobile_p0 {
    padding: 0px !important;
  }
  .mobile_m0 {
    margin: 0px !important;
  }
  .mobile_mt20 {
    margin: 20px !important;
  }
  .mobile_mx0 {
    margin-inline: 0px !important;
  }
  .mobile_p {
    padding-block: 10px !important;
  }
  .timeline_Item {
    flex-direction: column;
  }
  .timeline_sub {
    padding: 0px !important;
  }
  .sidebar_width {
    width: 100% !important;
  }
  .btn {
    padding-inline: 13px;
  }
  .pdfHFont {
    font-size: 16px;
  }

  .headcol {
    position: sticky;
    /* width: 5em; */
    left: 0px;
    top: auto;
    background-color: white;
  }
  .headtable {
    position: sticky;
    /* width: 5em; */
    left: 0px;
    top: auto;
    background-color: #213c51;
  }
  .table_border tr td:nth-child(2) {
    padding-left: 2px !important;
  }
  .mobile_bgsize {
    background-size: cover !important;
  }
  .mobile-visible {
    display: block !important;
    visibility: visible !important;
  }

  .mobile-invisible {
    visibility: hidden !important;
    display: none !important;
  }
  .mobile-my15 {
    margin-block: 12px;
  }

  .mobile-textCenter {
    text-align: center !important;
  }
  .animate .movingLBox {
    animation: moveLeft 2s ease-out 0s 1 normal forwards !important;
  }
  .online {
    width: 100%;
    border-radius: 0% !important;
    margin: 0px !important;
  }
  .mobile-border {
    border: 1px solid #eef3f7;
  }
}

.bg-blue {
  /* background-color: #1f263d; */
  background-color: #213c51;
}
.bg-blue1 {
  background-color: #1f263d;
  /* background-color: #213c51; */
}
.bg-Lblue {
  background-color: #eef3f7;
}

.bg-Lgray {
  background-color: #e6e6e6;
}
.bg-gray {
  background-color: #cccccc;
  /* background-color: #98a4b4; */
}
.pdfFont {
  padding: 0px !important;
  margin-left: 30px;
}
.text-blue {
  color: #213c51;
}
.text-brown {
  color: #a77662;
}

h1.title-line:after {
  content: "";
  display: block;
  width: 120px;
  height: 5px;
  /* background: #a77662; */
  background: #bc6c3c;
  /* margin: 0 auto; */
  margin-top: 8px;
  border-radius: 3px;
}

h1.title-line-center:after {
  content: "";
  display: block;
  width: 120px;
  height: 5px;
  /* background: #a77662; */
  background: #bc6c3c;
  margin: 0 auto;
  margin-top: 8px;
  border-radius: 3px;
}

h1.title-line:after {
  content: "";
  display: block;
  width: 120px;
  height: 5px;
  /* background: #a77662; */
  background: #bc6c3c;
  /* margin: 0 auto; */
  margin-top: 8px;
  border-radius: 3px;
}

#BgBanner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#BusinessBg {
  background-image: url("https://cdn.pixabay.com/photo/2023/02/08/18/36/tawny-owl-7777285_1280.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#BusinessBg1 {
  background-image: url("https://cdn.pixabay.com/photo/2023/05/29/00/24/blue-tit-8024809_1280.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#BusinessBg2 {
  background-image: url("https://cdn.pixabay.com/photo/2022/11/29/11/30/lake-7624330_1280.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#BusinessBg3 {
  background-image: url("https://cdn.pixabay.com/photo/2022/11/29/11/30/lake-7624330_1280.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.timeline::-webkit-scrollbar {
  width: 0; /* ซ่อน scrollbar ในเบราว์เซอร์ที่ใช้เทคโนโลยี WebKit เช่น Chrome, Safari */
}

/* เพื่อป้องกันการแสดง scrollbar ใน Firefox */
.timeline {
  scrollbar-width: none; /* Firefox 68+ */
}

.boxShadow {
  box-shadow: 10px 10px 5px 0px rgb(45, 79, 98);
  -webkit-box-shadow: 10px 10px 5px 0px rgb(45, 79, 98);
  -moz-box-shadow: 10px 10px 5px 0px rgb(45, 79, 98);
}

.change-color {
  background-color: #213c51;
  transition: background-color 0.3s ease;
}

.narbar_brand {
  height: 40px;
}
.narbar_link {
  height: 30px;
}

ol li {
  padding-left: 5px;
  color: black;
}

ul li {
  color: black;
  min-width: 40px;
}

li.pointer {
  list-style: none;
}

li.pointer:hover {
  color: #393a3f;
  cursor: pointer;
}
li.subPointer:hover {
  text-decoration-line: underline;
  cursor: pointer;
}

li.subPointer {
  list-style: circle;
  margin-left: 40px;
}

.sticky {
  position: sticky;
  top: 150px;
  /* background-color: yellow; */
  padding: 30px 50px;
  font-size: clamp(14px, 1.2vw, 18px);
  margin-top: 200px;
}

hr {
  border: 2px solid white;
}

.MuiPagination-root {
  button {
    color: white;
    font-size: 25px;
  }
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}

.MuiTabs-scrollButtons {
  width: 40px;
}

.css-ptiqhd-MuiSvgIcon-root {
  font-size: 2.5rem !important;
  /* margin: 50px; */
}

.css-n8417t-MuiSvgIcon-root-MuiPaginationItem-icon {
  font-size: 1.8rem !important;
}

.navHV:hover {
  background-color: lightgray !important;
}
.navHV:active {
  background-color: lightgray !important;
}

li.circle:before {
  content: "\f111";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: calc(var(--icon-space) * -1);
  width: var(--icon-space);
  color: #bc6c3c;
  margin-right: 10px;
}

li.circle {
  list-style: none;
}

.bg-blue-gradient {
  background: rgb(57, 116, 174);
  background: linear-gradient(
    90deg,
    rgba(57, 116, 174, 1) 0%,
    rgba(47, 86, 143, 1) 50%,
    rgba(35, 50, 116, 1) 100%
  );
}

.headerFont {
  font-size: clamp(24px, 3vw, 40px);
}

.headerPadding {
  padding-inline: clamp(25px, 4vw, 80px);
  padding-block: clamp(10px, 2vw, 30px);
}

.font {
  font-size: clamp(15px, 2vw, 20px);
}

.line_2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2 !important;
  overflow: hidden;
}

.line_3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3 !important;
  overflow: hidden;
}
.line_4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4 !important;
  overflow: hidden;
}

th,
td {
  padding: clamp(2px, 0.5vw, 10px);
  color: black;
  /* width: 100%; */
  font-size: clamp(12px, 1.5vw, 20px);
}

td,
th {
  margin: 0;
  white-space: nowrap;
  border-top-width: 0px;
}

.long {
  background: yellow;
  letter-spacing: 1em;
}

.color:nth-child(odd) {
  background-color: #e6e6e6;
}

.color:nth-child(even) {
  background-color: white;
}

.financial-table tr td:nth-child(n + 2) {
  min-width: clamp(50px, 7vw, 100px);
  text-align: center;
}
.financial-table tr td:nth-child(1) {
  text-wrap: wrap;
  min-width: 120px;
}

.table_border {
  border-collapse: separate;
  border-spacing: 0;
}
.table_border tr td,
.table_border tr th {
  border: 4px white solid;
}

.table_border tr td:nth-child(1),
.table_border tr td:nth-child(3) {
  text-align: center;
  padding-inline: clamp(10px, 2vw, 40px);
}

.table_border tr td:nth-child(4) {
  padding-inline: clamp(20px, 4vw, 70px);
  text-align: center;
}

.table_border tr td:nth-child(2) {
  padding-left: 3%;
}
.table_border tr th {
  text-align: center;
}

.tableB {
  position: sticky;
  left: 0px;
  top: auto;
  background-color: #213c51;
}
.tableW {
  position: sticky;
  left: 0px;
  top: auto;
  background-color: white;
  /* border: 4px solid white !important; */
}
.tableG {
  position: sticky;
  left: 0px;
  background-color: #cccccc;
}

li.MuiButtonBase-root {
  font-size: 20px !important;
  padding-inline: 50px !important;
}

@keyframes moveLeft {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes moveRight {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(0px);
  }
}

.animate .movingLBox {
  animation: moveLeft 2s ease-out 0s 1 normal forwards;
}

.animate .movingRBox {
  animation: moveRight 2s ease-out 0s 1 normal forwards;
}

.ModalCard:hover {
  background-color: #e6e6e6;
}

.list_animate {
  transition-property: transform;
  transition-duration: 1s;
  transform-origin: center;
  transform: scale(1.05, 1.05);
}

.listNews {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listNews::before {
  content: "";
  background-image: url("https://files.123freevectors.com/wp-content/solidbackground/dull-brown-free-solidcolor-background.jpg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.75;
}

#home {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

/*--------- /shareholding/Information -----------*/

/* one */
/* @property --num-one {
  syntax: "<integer>";
  initial-value: 212;
  inherits: false;
}

.animate .one {
  animation: counter-one 3s alternate ease-in-out;
  counter-reset: num-one var(--num-one);
  counter-reset: num-one 0;
  font: system-ui;
  padding: 2rem;
  color: black !important;
}

@keyframes counter-one {
  from {
    --num-one: 0;
  }
  to {
    --num-one: 212;
  }
}
.animate .one::after {
  content: counter(num-one);
} */

/* two */
/* @property --num-two {
  syntax: "<integer>";
  initial-value: 621;
  inherits: false;
}

.animate .two {
  animation: counter-two 3s alternate ease-in-out;
  counter-reset: num-two var(--num-two);
  font: system-ui;
  padding: 2rem;
  color: black !important;
}
.animate .two::after {
  content: counter(num);
}

@keyframes counter-two {
  from {
    --num-two: 0;
  }
  to {
    --num-two: 621;
  }
}
.animate .two::after {
  content: counter(num-two);
} */

/* three */
/* @property --percent {
  syntax: "<number>";
  initial-value: 0;
  inherits: false;
}
@property --temp {
  syntax: "<number>";
  initial-value: 0;
  inherits: false;
}
@property --v1 {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}
@property --v2 {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
} */

/* .three,
.four {
  font: monospace;
  padding: 2rem;
  transition: --percent 3s;
  --temp: calc(var(--percent));
  --v1: max(var(--temp) - 0.5, 0);
  --v2: max((var(--temp) - var(--v1)) * 100 - 0.5, 0);
  counter-reset: v1 var(--v1) v2 var(--v2);
}
.animate .five {
  font: monospace;
  padding: 2rem;
  transition: --percent 3s;
  --temp: calc(var(--percent));
  --v1: max(var(--temp) - 0.5, 0);
  --v2: max((var(--temp) - var(--v1)) * 1000 - 0.5, 0);
  counter-reset: v1 var(--v1) v2 var(--v2);
} */

/* .three::before,
.four::before {
  content: counter(v1) "." counter(v2, decimal-leading-zero);
} */
/* 
.animate .five::before {
  content: counter(v1) "," counter(v2);
} */

/* six */

/* @property --num-six {
  syntax: "<integer>";
  initial-value: 679;
  inherits: false;
}

.animate .six {
  animation: counter-six 3s alternate ease-in-out;
  counter-reset: num-six var(--num-six);
  font: system-ui;
  padding: 2rem;
  color: black !important;
}
.animate .six::after {
  content: counter(num);
}

@keyframes counter-six {
  from {
    --num-six: 0;
  }
  to {
    --num-six: 679;
  }
}
.animate .six::after {
  content: counter(num-six);
}

.online:hover {
  background-color: lightgrey;
}

.custom-dialog {
  width: 90%;
  max-width: none !important;
} */

/* ::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
} */

/* ซ่อนแถบเลื่อนของ Chrome เมื่อเนื้อหาสามารถแสดงได้ในหน้าเดียว */
/* body:not(.scrollable)::-webkit-scrollbar {
  display: none;
} */
